import type { Response } from 'types/api';
import type { Me } from 'types/models/auth';
import type { PartnerGettingStartedSteps } from 'types/models/partner';
import ApiError from 'utils/ApiError';
import config from 'utils/config';
import { getJSONOptions } from 'utils/options';
import { getRequest, postRequest, refreshToken, request } from 'utils/request';

// Does not use standard request-refresh lifecycle because we should never redirect back to login after if this request returns 401.
// By just throwing an error, the user and company will remain null and the user will redirect to login from the React routing.
// Note: even though the email and userId cookies are still short circuiting this API call on app load (see /redux/slices/me), they could be removed and we could
// rely solely on the HTTP only cookies and backend session status to dictate if the user is logged in.
export const fetchMe = async (): Promise<Response<Me>> => {
  const url = `${config.apiURL}/auth/me`;
  const options = getJSONOptions();

  try {
    return await request(url, options);
  } catch (error) {
    if (!(error instanceof ApiError)) throw error;

    // can't refresh, no cookies
    if (error.isUnauthorized && error.isCookieInvalid) throw new ApiError('Require authorization');
    // other error
    if (!(error.isUnauthorized && error.shouldRefresh)) throw new ApiError('Error fetching me');
    // try to refresh
    await refreshToken().catch((e) => e);
    // retry fetch
    return request(url, options);
  }
};

export const fetchGetStarted = (): Promise<Response<PartnerGettingStartedSteps>> =>
  getRequest('/auth/me/getting-started');

interface LogMeOutResponse {
  success: boolean;
}

export const logMeOut = (): Promise<LogMeOutResponse> => postRequest('/auth/logout');

type ConnectStripeResponse = {
  authorizeURL: string;
};

export const connectStripe = (): Promise<ConnectStripeResponse> =>
  postRequest('/auth/stripe/connect');
