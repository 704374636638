import Flex from '@react-css/flex';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SettingsMain, SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store';
import {
  selectCompanyId,
  selectIsBuyer,
  selectIsProductPlatformShopify,
} from 'store/selectors/me/company';
import Alert from 'storybook/stories/cells/Alert';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Toggle from 'storybook/stories/molecules/Toggle';
import styled from 'styled-components';
import { getCompany, updateCompany, type CompanyFlagsUpdate } from 'utils/api/companies';
import type ApiError from 'utils/ApiError';

const AlertWrapper = styled.div`
  padding-bottom: 24px;
`;

const CompanyFlagsSettings = () => {
  const companyId = useAppSelector(selectCompanyId);
  const alertQueue = useAlertQueue();
  const isBuyer = useSelector(selectIsBuyer);
  const isShopify = useSelector(selectIsProductPlatformShopify);

  /**
   * Form
   */

  type FormFields = CompanyFlagsUpdate & {
    enableShippingLinesShopify: boolean;
  };

  const { handleSubmit, register, reset } = useForm<FormFields>({
    mode: 'onChange',
  });

  /**
   * Queries
   */

  const fetchingCompany = useQuery({
    queryKey: ['getCompany', companyId] as const,
    queryFn: () => getCompany(companyId!),
    enabled: !!companyId,
    onSuccess: ({ enableShippingLinesShopify, flags }) => {
      reset({ enableShippingLinesShopify, ...flags });
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  /**
   * Mutations
   */

  const updatingCompany = useMutation({
    mutationFn: (data: FormFields) => {
      const { enableShippingLinesShopify, ...flags } = data;
      return updateCompany(companyId!, { enableShippingLinesShopify, flags });
    },
    onSuccess: () => {
      alertQueue.addSuccessAlert('Company flags updated', 'Company flags have been updated');
      fetchingCompany.refetch();
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  /**
   * Event Handlers
   */

  const onSubmit = handleSubmit((values) => {
    const data = { ...values };

    // Ensure that only one of the compared at price flags is set
    if (values.overwriteComparedAtPrice) {
      data.useRetailPriceForComparedAt = false;
    }

    if (values.useRetailPriceForComparedAt) {
      data.overwriteComparedAtPrice = false;
    }

    updatingCompany.mutate(data);
  });

  /**
   * Render
   */

  return (
    <SettingsMain>
      <SettingsPageHeader title="Rules" />
      <AlertWrapper>
        <Alert
          title="These rules are applied to all of your partnerships and can not be overridden"
          type="warning"
        >
          <SupportLink article="trbj92v9ol-managing-rules">
            Learn more about company rules
          </SupportLink>
        </Alert>
      </AlertWrapper>

      <form onSubmit={onSubmit}>
        <Flex column gap="16px">
          {isBuyer && (
            <Toggle
              {...register('ignoreInventoryType')}
              description="If enabled, allow products to sell regardless of inventory."
            >
              Allow Overselling
            </Toggle>
          )}

          {isBuyer && (
            <Toggle
              {...register('pushProductsAsTaxable')}
              description="If your seller has a product marked as taxable in Shopify, we will sync that through."
            >
              Sync Product Tax Status
            </Toggle>
          )}

          {isBuyer && (
            <Toggle
              {...register('useRetailPriceForComparedAt')}
              description={
                <>
                  Sync retail price from your seller&apos;s price list to the compared-at field. If
                  you would like to mirror your seller&apos;s compared-at price,{' '}
                  <Link to="/partners">you can set this up in your partnership settings</Link>.
                </>
              }
            >
              Use Retail Price for Compared At
            </Toggle>
          )}

          {isShopify && (
            <Toggle
              {...register('enableShippingLinesShopify')}
              description="Includes the shipping rate name and price in the order details. Both buyer and seller must have this setting enabled for shipping lines added to each order."
            >
              Sync shipping lines on order
            </Toggle>
          )}

          <Flex justifyEnd>
            <PrimaryButton type="submit" disabled={updatingCompany.isLoading}>
              Submit
            </PrimaryButton>
          </Flex>
        </Flex>
      </form>
    </SettingsMain>
  );
};

export default CompanyFlagsSettings;
