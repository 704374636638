/* eslint-disable jsx-a11y/label-has-associated-control */
import qs from 'qs';
import { useState } from 'react';

import { sudoUser } from 'store/slices/me';

import Flex from '@react-css/flex';
import { isRejected } from '@reduxjs/toolkit';
import { SettingsMain, SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import type { Company } from 'types/models/company';
import CompanySelector from './CompanySelector';

const AdminSettings = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const alertQueue = useAlertQueue();
  const { sudoQuery } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const stringSudoQuery = typeof sudoQuery === 'string' ? sudoQuery : undefined;
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

  const getReturnCompany = () => {
    if (!user || (user.memberships ?? []).length === 0) {
      return '';
    }
    return user.memberships[0].companyObjectId || '';
  };

  const doSudo = (companyObjectId?: string) => {
    if (!companyObjectId) {
      alertQueue.addErrorAlert('Error', 'Could not determine which company to log in to.');
      return;
    }

    dispatch(sudoUser({ companyObjectId })).then((action) => {
      if (isRejected(action)) {
        alertQueue.addErrorAlert('Error', `Could not log in to company. ${action.error.message}`);
        return;
      }
      window.location.replace(window.location.pathname);
    });
  };

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    doSudo(selectedCompany?._id);
  };

  return (
    <SettingsMain>
      <SettingsPageHeader title="Admin" />
      <h4 className="mb-4">Log in to Another Company</h4>

      <Flex column gap="8px">
        <form onSubmit={onSubmit}>
          <CompanySelector onSelect={setSelectedCompany} defaultSearch={stringSudoQuery} />

          <Flex gap="16px">
            <PrimaryButton size="small" type="submit" disabled={!selectedCompany?._id}>
              Submit
            </PrimaryButton>
            <SecondaryButton size="small" onClick={() => doSudo(getReturnCompany())}>
              Return to my Company
            </SecondaryButton>
          </Flex>
        </form>
      </Flex>
    </SettingsMain>
  );
};

export default AdminSettings;
