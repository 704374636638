import { format, getYear, parseISO } from 'date-fns';

// isZeroDate only cares about the year being set to 0001
export const isZeroDate = (arg?: Date | string | null) => {
  if (!arg) return true;

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return getYear(date) === 1;
};

export const prettyDate = (arg?: Date | string | null) => {
  if (!arg || isZeroDate(arg)) return '';

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return format(date, 'MMM d, yyyy');
};

export const prettyTime = (arg?: Date | string | null) => {
  if (!arg || isZeroDate(arg)) return '';

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return format(date, 'h:mm a');
};

export const prettyDateTime = (arg?: Date | string | null) => {
  if (!arg || isZeroDate(arg)) return '';

  const date = typeof arg === 'string' ? parseISO(arg) : arg;

  return format(date, "MMM d, yyyy 'at' h:mm a");
};
