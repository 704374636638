import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux';
import agreementsReducer from 'store/slices/agreements';
import alertsReducer from 'store/slices/alerts';
import companyReducer from 'store/slices/company';
import ediInfoReducer from 'store/slices/edi/info';
import ediSchemasReducer from 'store/slices/edi/schema';
import errorsReducer from 'store/slices/errors';
import getStartedReducer from 'store/slices/getStarted';
import meReducer from 'store/slices/me';
import ordersReducer from 'store/slices/ordersV2';
import partnersReducer from 'store/slices/partners';
import settingsReducer from 'store/slices/settings';
import sftpUserReducer from 'store/slices/sftpUser';
import stripeReducer from 'store/slices/stripe';
import config from 'utils/config';

export const reducer = {
  me: meReducer,
  company: companyReducer,
  getStarted: getStartedReducer,
  errors: errorsReducer,
  stripe: stripeReducer,
  settings: settingsReducer,
  partners: partnersReducer,
  sftpUser: sftpUserReducer,
  agreements: agreementsReducer,
  orders: ordersReducer,
  ediSchemas: ediSchemasReducer,
  ediInfo: ediInfoReducer,
  partnersState: partnersReducer,
  alerts: alertsReducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      // Disables immutableCheck & serializableCheck in the test environment to avoid flaky test failures in CI
      // Otherwise configures the paths to our legacy reducers which unfortunately mutate state
      serializableCheck: !config.isTest,
      immutableCheck: !config.isTest && {
        // we are mutating state in components that use old actions/reducers, ignore check for now until fixed
        ignoredPaths: ['ordersState'],
      },
    });

    return middleware;
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
