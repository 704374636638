import { isEmpty } from 'lodash';
import {
  InvoicePosterReason,
  type Partner,
  type PartnerGettingStartedSteps,
} from 'types/models/partner';

export const friendlyInvoicePosterReason = (reason: InvoicePosterReason) => {
  switch (reason) {
    case InvoicePosterReason.BuyerPaymentsDisabled: {
      return 'The buyer has disabled all order payments.';
    }
    case InvoicePosterReason.SellerPaymentsDisabled: {
      return 'The seller has disabled all order payments.';
    }
    case InvoicePosterReason.PartnerBillingDisabled: {
      return 'Automatic invoicing is disabled for this partnership.';
    }
    case InvoicePosterReason.OrderIsTest: {
      return 'This is a test order.';
    }
    case InvoicePosterReason.NoGateway: {
      return 'The seller has not set up Stripe.';
    }
    default: {
      return '';
    }
  }
};

export const getNameOrEmail = (
  { buyerName, sellerName, reference: email }: Partner,
  isBuyer: boolean
) => {
  if (isBuyer && isEmpty(sellerName)) return email;
  if (!isBuyer && isEmpty(buyerName)) return email;

  return isBuyer ? sellerName : buyerName;
};

type Step = {
  label: string;
  group: string;
  articleId?: string;
};

// The order of this array determines the order of groups
export const GETTING_STARTED_GROUPS = [
  { label: 'Set Up Company Profile', value: 'company' },
  { label: 'Connect Platform', value: 'platform' },
  { label: 'Create Price Lists', value: 'pricing' },
  { label: 'Configure Billing', value: 'billing' },
  { label: 'Complete Assigned Actions', value: 'actions' },
];

// orders, products
const STEPS: Record<string, Step> = {
  connectShopify: {
    label: 'Connect Shopify',
    group: 'platform',
    articleId: 'eqxyqt0mtj',
  },
  connectBigCommerce: {
    label: 'Connect Big Commerce',
    group: 'platform',
    articleId: 'mvbcncoh60',
  },
  connectWooCommerce: {
    label: 'Connect WooCommmerce',
    group: 'platform',
    articleId: '3ad5l1ysc6',
  },
  connectMagento2: {
    label: 'Connect Magento 2',
    group: 'platform',
  },
  connectStripe: {
    label: 'Connect Stripe',
    group: 'billing',
    articleId: '8lwln18tin',
  },
  addPaymentMethod: {
    label: 'Add Payment Method',
    group: 'billing',
  },
  addBillingAddress: {
    label: 'Add Billing Address',
    group: 'company',
    articleId: 'q4znpe6bo2',
  },
  addShippingAddress: {
    label: 'Add Shipping Address',
    group: 'company',
  },
  addReturnsAddress: {
    label: 'Add Returns Address',
    group: 'company',
  },
  inviteTeam: {
    label: 'Invite Team',
    group: 'company',
    articleId: 'dvzdivcsdu',
  },
  invitePartners: {
    label: 'Invite Partners',
    group: 'company',
    articleId: 'dvwv9wl2ws',
  },
  activeProducts: { label: 'Sync Products', group: 'platform' },
  createdPriceList: {
    label: 'Setup Price List',
    group: 'prices',
    articleId: 'ia2nwgi8be',
  },
  createSFTPCreds: {
    label: 'Create SFTP Credentials',
    group: 'platform',
  },
  createExternalSFTPCredentials: {
    label: 'Create External SFTP Credentials',
    group: 'platform',
  },
  uploadSFTPFile: {
    label: 'Upload SFTP File',
    group: 'platform',
  },
  createCSVMappings: {
    label: 'Create CSV Mappings',
    group: 'platform',
    articleId: 'delewtxyn5#step_3_configure_your_product_file_s_csv_mappings',
  },
  ediSchemaSetup: {
    label: 'Setup EDI Schema',
    group: 'platform',
  },
  testOrder: {
    label: 'Test Order',
    group: 'platform',
  },
  actionsAssigned: {
    label: 'Required Actions',
    group: 'actions',
    articleId: 'gc59nwyivb',
  },
  configureEDISettings: {
    label: 'Configure EDI Settings',
    group: 'platform',
  },
  viewEDISpecifications: {
    label: 'View EDI Specifications',
    group: 'platform',
  },
};

export const groupPendingGettingStartedSteps = (gettingStarted: PartnerGettingStartedSteps) => {
  type GettingStartedStepKey = keyof typeof gettingStarted;

  const groupedSteps: Record<string, Step[]> = {};

  // Iterate over API-supplied steps
  Object.keys(gettingStarted).forEach((stepName) => {
    const gettingStartedStep = gettingStarted[stepName as GettingStartedStepKey];
    // Toss out steps that are not required or already complete
    if (!gettingStartedStep.required || gettingStartedStep.status !== 'incomplete') return;

    // Toss invite team and invite partners - knowing whether your
    // partner has formed other partnerships or added users is not useful
    if (stepName === 'invitePartners' || stepName === 'inviteTeam') return;

    // Get friendly step data
    const step = STEPS[stepName];
    if (!step) return;

    // Add step to group
    if (!groupedSteps[step.group]) groupedSteps[step.group] = [];
    groupedSteps[step.group].push(step);
  });

  // Convert into an ordered list of groups using the GETTING_STARTED_GROUPS array
  return GETTING_STARTED_GROUPS.filter((group) => groupedSteps[group.value]).map((group) => {
    return {
      label: group.label,
      steps: groupedSteps[group.value],
    };
  });
};
