import Flex from '@react-css/flex';
import { useSelector } from 'react-redux';
import { selectIsProductPlatformShopify } from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import Heading from 'storybook/stories/molecules/Heading';
import Toggle from 'storybook/stories/molecules/Toggle';
import type { Partner } from 'types/models/partner';
import type { UpdatePartnerParams } from 'utils/api/partners';

interface PartnerFlagSettingsProps {
  partner: Partner;
  updatePartner: (params: UpdatePartnerParams) => void;
}

const PartnerFlagSettings = ({ partner, updatePartner }: PartnerFlagSettingsProps) => {
  const isUsingShopify = useSelector(selectIsProductPlatformShopify);
  return (
    <Card>
      <Card.Head>
        <Heading variant="Headings/H2" color="bodyTextSecondary" as="h1">
          Partner Flags
        </Heading>
      </Card.Head>

      <Card.Body>
        <Flex column gap="16px">
          <Toggle
            defaultChecked={partner.requireFulfillmentTrackingInfo}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updatePartner({ requireFulfillmentTrackingInfo: event.target.checked })
            }
            description="If enabled, tracking codes and carrier names will be required for all fulfillments"
          >
            Require Fulfillment Tracking Info
          </Toggle>

          {isUsingShopify && (
            <Toggle
              defaultChecked={partner.syncSellerCompareAtPrice}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updatePartner({ syncSellerCompareAtPrice: event.target.checked })
              }
              description="If enabled, sync the seller's Compare-At Price for each product as long as it is lower than the retail price"
            >
              Sync Seller Compare-At Price
            </Toggle>
          )}
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PartnerFlagSettings;
