import { postRequest } from 'utils/request';

const requestSupport = (message: string) =>
  postRequest('/support/request', {
    message,
    page: window.location.pathname,
    type: 'support',
  });

export default requestSupport;
